import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Stack, Chip, Avatar, Typography, Button, Tooltip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// import { fCurrency } from '../../utils/formatNumber';
// components
import Label from '../../components/Label';
// import { ColorPreview } from '../../components/color-utils';
import Iconify from 'src/components/Iconify';
import { green } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { handleResponse } from "src/utils/responseHandler"
import axios from 'src/axios'
import { getURL } from 'src/utils/config';
// ----------------------------------------------------------------------
const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
// ----------------------------------------------------------------------

AuditListCard.propTypes = {
  audit: PropTypes.object,
};

function formatDate(datetimeString) {
  // Parse the datetime string into a Date object
  const date = new Date(datetimeString);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function AuditListCard({ audit, handleDialog }) {
  //debugger
  // const [isSubmitting, setIsSubmitting] = useState(false)
  const { EventNo, EventType,CameraId, ClientCreatedOn, DetectedType,EventTime, devicename, SnapshotUrl, VideoUrl ,SupervisorComments ,Priority } = audit;
  const snapurl = SnapshotUrl !== null ? SnapshotUrl : `https://vdointel.online/vdointel/deviceimages/${CameraId}.jpg`
  const IsAgentBased = localStorage.getItem('IsAgentBased');

  const formattedDate =EventTime?EventTime.replace('T', ' '):'';
  const latestFormattedDate =formattedDate?formatDate(formattedDate):'';
  const [showMore, setShowMore] = useState(false);
  //console.log("formattedDate",latestFormattedDate)
  // const sendMail = () => {
  //   setIsSubmitting(true)
  //     axios({
  //       method: 'get',
  //       url: getURL(`client-info/send-client-mail-consolidated?devicename=${devicename}&detectedtype=${DetectedType}&videourl=${VideoUrl}`),
  //       validateStatus: (status) => handleResponse(status),
  //   }).then(function (response) {
  //       setIsSubmitting(false)
  //       if (response.status === 200) {
  //         window.alert("Email has been sent successfully.")
  //       } else {
  //         window.alert("Something went wrong. Please try again later")
  //       }
  //   })
  // }

  const alignComments1 = (comments)=>{
    debugger
    console.log(comments)
    let lines=comments.split('\n')

    return (
      <ul>
        {lines.map((line, index) => (
          <li>
            {line}
          </li>
        ))}
      </ul>
    )
  }


  const alignComments = (comments) => {
    debugger;
    console.log(comments);
  
    // Replace \n and \N with empty strings
    const cleanedComments = comments.replace(/\\n|\\N/g, '');
    const lines = cleanedComments.split('\n');
  
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index}>
            {line}
          </li>
        ))}
      </ul>
    );
  }
  

  return (
    <>
  {IsAgentBased === "0" ? (
    <Card sx={{ p: 1}}>
        <Card>
        <div style={{ display: 'flex', width: '100%' }}>

        <Box onClick={() => handleDialog(audit)} 
          sx={{ 
            width: '50%',
            minHeight: 200, 
            position: 'relative', 
            background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', 
            cursor: "pointer" 
        }}
        >
          
          {/* {ClientCreatedOn && (
              <Label
                variant="filled"
                color={'info'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >{ClientCreatedOn}</Label>
            )}
          <Label
              variant="filled"
              color='secondary'
              sx={{
                zIndex: 9,
                top: 42,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              Audit Id {EventNo}
            </Label>
            
            <Label
              variant="filled"
              color='secondary'
              sx={{
                zIndex: 9,
                top: 68,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {devicename}
            </Label> */}
            <Avatar
              sx={{
                zIndex: 9,
                top: '45%',
                left: '45%',
                position: 'absolute',
                textTransform: 'uppercase',
                bgcolor: green[500]
              }}
            >
              <Iconify icon="eva:play-circle-outline" width={30} height={30} />
            </Avatar>
            {snapurl && (
              <ProductImgStyle alt={ClientCreatedOn} src={snapurl} />
            )}
          </Box>
          <Box sx={{p: 2}}>
          {/* <Typography color="gray" sx={{fontSize: "0.75rem"}}>{ClientCreatedOn}</Typography> */}
          {/* <Typography variant='h6' sx={{ fontSize: "1rem", textTransform: "capitalize" }}>{DetectedType}</Typography> */}
          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            // sx={{pt: 1}}
            
            >
            <Typography sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Camera: </Typography>
          </Stack>
          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            // sx={{pt: 1}}
            
            >
            <Typography sx={{ fontSize: "1.1rem", textTransform: "capitalize" }}>{devicename?devicename : ''} </Typography>
          </Stack>

          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            
            >
            <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Type Time: </Typography>
        
            
          </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Typography sx={{ fontSize: '1.1rem', wordBreak: 'break-word' }}>
                      {DetectedType ? DetectedType : ''}
                    </Typography>
            </Stack>


          <Stack direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      >
                      <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Time : </Typography>
                  </Stack>
                  <Stack direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      >
                      <Typography sx={{ fontSize: "1.1rem" }}>{latestFormattedDate ? latestFormattedDate :''}</Typography>
                  </Stack>

          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            
            >
            <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Number: </Typography>
            
          </Stack>
          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            
            >
            <Typography sx={{ fontSize: "1.1rem" }}>{EventNo ? EventNo :''}</Typography>
            
          </Stack>
          

          {/* {Number(localStorage.getItem("client_id")) === 831 && (
            <LoadingButton 
            sx={{ mt: 2}}
            fullWidth
            variant="contained"
            loading={isSubmitting}
            onClick={sendMail}
            >
            Send Mail
            </LoadingButton>
          )}         */}
        </Box>

        </div> 
        </Card>

        <>


        {/* <Box sx={{p: 2}}>
          <Typography color="gray" sx={{fontSize: "0.75rem"}}>{ClientCreatedOn}</Typography>
          <Typography variant='h6' sx={{ fontSize: "1rem", textTransform: "capitalize" }}>{DetectedType}</Typography>
          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{pt: 1}}
            
            >
            <Typography sx={{ fontSize: "0.9rem", fontWeight: 800 }}>Camera: </Typography>
            <Typography sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}>{devicename}</Typography>
          </Stack>
          <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            
            >
            <Typography  sx={{ fontSize: "0.9rem", fontWeight: 800 }}>Audit ID: </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>{EventNo}</Typography>
            
          </Stack>
          {/* {Number(localStorage.getItem("client_id")) === 831 && (
            <LoadingButton 
            sx={{ mt: 2}}
            fullWidth
            variant="contained"
            loading={isSubmitting}
            onClick={sendMail}
            >
            Send Mail
            </LoadingButton>
          )}         */}
        {/* </Box>  */}


        </>

        {/* <Stack direction="row" spacing={1} sx={{ m: 2 }}>
          {(DetectedType) && [...DetectedType.split(',')].map((value, key) => (
            <Chip sx={{ textTransform: "capitalize" }} key={key} avatar={<Avatar>{value.charAt(0).toUpperCase() || ''}</Avatar>} label={value} />
          ))}
        </Stack>     */}
    </Card>

    ) : (
      
        // <Card sx={{ p: 1,height:'100%',mt:1}}>
        //   <Grid container spacing={2}>
        //     <Grid item xs={6}>
        //         <Box 
        //           onClick={() => handleDialog(audit)} 
        //           sx={{ 
        //               width: '100%', // occupy 50% of the container width
        //               position: 'relative', 
        //               background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', 
        //               cursor: "pointer",
        //              // minHeight:200,
        //               height:225,
        //               display: 'flex',
        //               justifyContent: 'center',
        //               alignItems: 'center'
        //           }}
        //           >
        //           <Avatar
        //               sx={{
        //               zIndex: 9,
        //               // top: '40%',
        //               // left: '45%',
        //               // position: 'absolute',
        //               textTransform: 'uppercase',
        //               bgcolor: green[500]
        //               }}
        //           >
        //               <Iconify icon="eva:play-circle-outline" width={30} height={30} />
        //           </Avatar>
        //           {snapurl && (
        //               <ProductImgStyle alt={ClientCreatedOn} src={snapurl}/>
        //           )}
        //         </Box>
        //     </Grid>
        //     <Grid item xs={6}>
        //           <Box sx={{ width: '90%', p: 0,height:300}}>
        //           {/* <Typography color="gray" sx={{fontSize: "0.75rem"}}>{ClientCreatedOn}</Typography> */}
        //           <Stack direction="row"
        //             justifyContent="flex-start"
        //             alignItems="center"
        //             spacing={1}           
        //             >
        //             <Typography sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Camera: </Typography>
        //           </Stack>
        //           <Stack direction="row"
        //             justifyContent="flex-start"
        //             alignItems="center"
        //             spacing={1}
        //             // sx={{pt: 1}}            
        //             >
        //             <Typography sx={{ fontSize: "1.1rem", textTransform: "capitalize" }}>{devicename}</Typography>
        //           </Stack>
        //           {/* <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography  sx={{ fontSize: "0.9rem", fontWeight: 800 }}>Audit ID: </Typography>
        //               <Typography sx={{ fontSize: "0.9rem" }}>{EventNo}</Typography>
        //           </Stack> */}
        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Priority : </Typography>
        //               <Typography sx={{ fontSize: "1.1rem" }}>{Priority === 0 ? 
                      
        //               <Typography >Normal</Typography>: 
        //               <Typography >High</Typography>
                      
        //               }
                        

        //               </Typography>
        //           </Stack>
        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Type : </Typography>
        //           </Stack>

        //           {/* <Stack direction="row"
        //             justifyContent="flex-start"
        //             alignItems="center"
        //             spacing={1}           
        //             >
        //               <Typography sx={{ fontSize: "0.9rem" }}>{EventType}</Typography>    
        //           </Stack> */}

        //           <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        //             <Typography sx={{ fontSize: "1.1rem" }}>               
        //                 {EventType}       
        //             </Typography>
        //           </Stack>

        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Time : </Typography>
        //           </Stack>

        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography sx={{ fontSize: "1.1rem" }}>{latestFormattedDate}</Typography>
        //           </Stack>

        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography  sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Event Number : </Typography>
        //           </Stack>

        //           <Stack direction="row"
        //               justifyContent="flex-start"
        //               alignItems="center"
        //               spacing={1}
        //               >
        //               <Typography sx={{ fontSize: "1.1rem" }}>{EventNo}</Typography>
        //           </Stack>
        //           {/* {Number(localStorage.getItem("client_id")) === 831 && (
        //               <LoadingButton 
        //               sx={{ mt: 2}}
        //               fullWidth
        //               variant="contained"
        //               loading={isSubmitting}
        //               onClick={sendMail}
        //               >
        //               Send Mail
        //               </LoadingButton>
        //           )}         */}
        //           </Box>
        //     </Grid>
        //     <Grid item xs={12} sx={{ marginTop: -8 }}>
        //           <Box sx={{p: 1}}>
        //             <Typography sx={{ fontSize: "1.2rem", fontWeight: 800 }}>Comment:  </Typography>                 
        //             <Typography sx={{ fontSize: "1.1rem", textTransform: "capitalize",marginLeft:'8px'}}>
        //             {alignComments(SupervisorComments)}
        //           </Typography>                 
        //           </Box>
        //     </Grid>
        //   </Grid>        
        // </Card> 
<Card sx={{ p: 1, height: '100%', mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box 
            onClick={() => handleDialog(audit)} 
            sx={{ 
              width: '100%',
              position: 'relative', 
              background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', 
              cursor: "pointer",
              height: { xs: 'auto', md: '100%' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {/* <Avatar
              sx={{
                zIndex: 9,
                textTransform: 'uppercase',
                bgcolor: green[500]
              }}
            > */}
              {/* <Iconify icon="eva:play-circle-outline" width={30} height={30} />
            </Avatar> */}
            {snapurl && (
                            <Box
                            component="img"
                            alt={ClientCreatedOn}
                            src={snapurl}
                            sx={{
                              height: { xs: '200px', md: '100%' },
                              width: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        )}
                        <Avatar
                          sx={{
                            position: 'absolute',
                            zIndex: 9,
                            textTransform: 'uppercase',
                            bgcolor: green[500],
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}
                        >
                          <Iconify icon="eva:play-circle-outline" width={30} height={30} />
                        </Avatar>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%', p: 0, height: '100%' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Camera: </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}>{devicename ? devicename : ''}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Priority: </Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>{Priority === 0 ? 'Normal' : 'High'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Event Type: </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "0.9rem" }}> {EventType ? EventType : ''}</Typography>
            </Stack>
        
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Event Time: </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "0.9rem" }}>{latestFormattedDate ? latestFormattedDate : ''}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Event Number: </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: "0.9rem" }}>{EventNo ? EventNo : ''}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} mt={-2}>
          <Box sx={{ p: 1 }}>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Comment:</Typography>
            <Typography sx={{ fontSize: "0.9rem", textTransform: "capitalize", ml: 1 }}>
              {alignComments(SupervisorComments ? SupervisorComments :'')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
   
         
    )}

    </>

  );
}