import { useState, useEffect } from "react"
import { handleResponse } from "src/utils/responseHandler"
import axios from '../axios'
import { getURL } from 'src/utils/config';
import { format } from "date-fns";
import dayjs from "dayjs";
import { getTimezoneList } from "./analytics";


const useAuditList = (userType,IsAgentBased) => {

    // console.log('userType', userType)

    const RUN_FREQ_TIME = 30 //sec

    const BUZZER_CAMS = [6693, 6694, 6695, 6696, 6697]
    const buzzer = new Audio('/sounds/buzzer-or-wrong-answer.mp3')
    buzzer.load()

    

    const [alldata, dataSetup] = useState({
        isGetData: true,
        isLoad: true,
        customFilterData: [],
        data: [],
        filterData: [],
        lastId: null,
        firstId: null,
        reload: true,
        isReloadForNewData: true,
    })

    const [search, setSearch] = useState('')
    const [isEscalating, setEscalat] = useState(false)
    const [open, setOpen] = useState(false)
    const [cameras, setCameras] = useState([])
    const [analytics, setAnalytics] = useState([])
    const [fromDate, setFromDate] = useState( IsAgentBased === '0' ? dayjs().startOf('day') :dayjs().startOf('month').hour(0).minute(0))
    const [toDate, setToDate] = useState(dayjs().endOf('day'))
    const [detectedType, setDetectedType] = useState([])
    const [device, setDevice] = useState([])
    const [alertStatus, setAlertStatus] = useState({ open: false, title: "", description: ""})
    const [initialToTime,setInitialToTime]=useState(true)
    const [resData,setResData]=useState([])
    const [isGet,setIsGet] = useState(false)

    const playBuzzer = () => buzzer.play().catch((err) => handleNotificationBar(true, "Enable Sound Interaction with User Consent", "To enhance your web experience with sound effects, we kindly ask for your permission to play a buzzer. Simply click the 'OK' button"))

    const handleNotificationBar = (isOpen=false, title="", description= "") => {
        if (!isOpen)
            buzzer.play()
        setAlertStatus({...alertStatus, open: isOpen, title: title, description: description})
    }

    const handleFromDate = (value) => {
        setFromDate(value)
    }
    // const handleToDate1 = (value) => {
    //     // Create a new Date object based on the selected value
      
    //     const adjustedDate = value.endOf('day');

    //     // Set the time to 23:59:00
    //     // selectedDate.setHours(23);
    //     // selectedDate.setMinutes(59);
    //     // selectedDate.setSeconds(0);
      
    //     // Update the state with the new Date object
    //     setToDate(adjustedDate);
    //   };

      const handleToDate = (value) => {
        // if(initialToTime){
        // const adjustedDate = value.endOf('day');
        // setToDate(adjustedDate);
        // setInitialToTime(false)
        // }
        // else{
        //   setToDate(value);
        // }
        setToDate(value);
      };


    const handleDetectedType = (_, value) => {
        setDetectedType(value)
    }

    const handleDevice = (_, value) => {
        //debugger
        setDevice(value)
    }


    const clearAll = () => { 
        dataSetup({
            ...alldata,
            isLoad: false,
            isGetData: false,
            customFilterData: [...alldata.data],
            filterData: filterData(search, alldata.data),
            reload: false
        })
    }

    const handleDelete = (filterType, value = null) => {
        if (filterType === 'fromDate') {
            setFromDate(null)
            if (!(toDate && detectedType && device))
                clearAll()
        } else if (filterType === 'toDate') {
            setToDate(null)
            if (!(fromDate && detectedType && device))
                clearAll()
        } else if (filterType === 'detectedType') {
            setDetectedType([...detectedType.filter((_, index) => index !== value)])
            if (!(fromDate && toDate && device))
                clearAll()
        } else if (filterType === 'camera') {
            setDevice([...device.filter((_, index) => index !== value)])
            if (!(fromDate && toDate && detectedType))
                clearAll()
        } else if (filterType === 'clear') {
            setFromDate(null)
            setToDate(null)
            setDetectedType([])
            setDevice([])
            clearAll()
        }
    }

    const handlerGetFilterData = () => {
        //debugger
        // console.log("Filter Data for Escalated Video", fromDate , toDate ,detectedType)

        setIsGet(true)
        if (fromDate || toDate || detectedType.length > 0 || device.length > 0){
            dataSetup({ ...alldata, isReloadForNewData: false, isGetData: true, isLoad: true })
        }
        else if(fromDate || toDate  || device.length > 0){
            dataSetup({ ...alldata, isReloadForNewData: false, isGetData: true, isLoad: true })

        }
    }

    const jsonToParam = (data) => Object.keys(data).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&')

    // useEffect(() => {
    //     if (open) {
    //         // buzzer.autoplay = true
    //         // buzzer.play()
    //     }
    // }, [open])


    const getCameras = () => {
        axios({
            method: 'get',
            url: getURL('devices?status=1'),
            validateStatus: (status) => handleResponse(status),
        }).then(function (response) {
            if (response.status === 200) {
                if(response.data !== null){
                    setCameras(response.data)
                }
            }
        })
    }

    const getAnalytics = () => {
        axios({
            method: 'get',
            url: getURL('analytics-sub/client-wise-analytics'),
            validateStatus: (status) => handleResponse(status),
        }).then(function (response) {
            if (response.status === 200) {
                setAnalytics([...response.data])
            }
        })
    }


    // const getFilters = () => {

    //     //debugger
    //     const timezoneId = localStorage.getItem('timezone_id')

    //     const fromDatefor = fromDate;
    //     const toDatefor = toDate;

    //     getTimezoneList((response)=>{
    //         if(response.status===200){
    //           let data = response.data;
    //           console.log(data)
    //           //debugger
    //           let timeOffset = data.filter(item=>item.Id ===parseInt(timezoneId,10))[0].Time;
              
    //           //timeOffset='-16:30';
        
    //           const now = new Date();
    //           //console.log(now)
        
    //           const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
    //           const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
        
    //           const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
        
    //           //debugger
    //           console.log(now,adjustedTime)
    //           console.log(adjustedTime.getTime())
    //           console.log(now.getTime())
        
    //           if(now.getDate() !== adjustedTime.getDate()){
    //             const adjustedDay = adjustedTime.getDate();
    //             const adjustedMonth = adjustedTime.getMonth(); 
    //             const adjustedYear = adjustedTime.getFullYear();
          
    //             setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
    //             setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));
          
    //             fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
    //             toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
    //           }   
              
    //           const nowHours = now.getHours();
    //           const nowMinutes = now.getMinutes();
    //           const adjustedHours = adjustedTime.getHours();
    //           const adjustedMinutes = adjustedTime.getMinutes();
              
    //           if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
    //               const adjTimeValue = dayjs(adjustedTime)
    //               setToDate(adjTimeValue); 
    //               toDatefor = adjTimeValue;
    //           }
        
    //         }
    //       })

    //     const isValidDate = (value) => {
    //         const date = new Date(value);
    //         return !isNaN(date.getTime());
    //       };

    //     const parseDate = (dateString) => {
    //         if (!isValidDate(dateString)) {
    //           console.error("Invalid date:", dateString);
    //           return null; // Return null if the date string is invalid
    //         }
    //         return new Date(dateString);
    //       };
    
    //       const formatDateTime = (date) => {
    //         const year = date.getFullYear();
    //         const month = String(date.getMonth() + 1).padStart(2, '0');
    //         const day = String(date.getDate()).padStart(2, '0');
    //         const hours = String(date.getHours()).padStart(2, '0');
    //         const minutes = String(date.getMinutes()).padStart(2, '0');
    //         const seconds = String(date.getSeconds()).padStart(2, '0');
    
    //         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //       };
    






    //     const filters = {};

    //     if (IsAgentBased === '0') {
    //         const today = new Date();
    //         const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    //         const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    //         filters.fromdate = formatDateTime(startOfDay);
    //         filters.todate = formatDateTime(endOfDay);
    //     } 
        
    //     else {

    //     if (fromDate && !toDate) {
    //         filters.fromdate = formatDateTime(new Date(fromDate), 'yyyy-MM-dd HH:mm:SS')
    //     } else if (!fromDate && toDate) {
    //         filters.todate = formatDateTime(new Date(toDate), 'yyyy-MM-dd HH:mm:SS')
    //     } else if (fromDate && toDate) {
    //         filters.fromdate = formatDateTime(new Date(fromDate), 'yyyy-MM-dd HH:mm:SS')
    //         filters.todate = formatDateTime(new Date(toDate), 'yyyy-MM-dd HH:mm:SS')
    //     }
    //     }
    //     if (detectedType) {
    //         filters.detectedtype = (detectedType.map((item) => item.Id)).join(',')
    //     }
    //     if (device) {
    //         filters.camera = (device.map((item) => item.uid?item.uid:item)).join(',')
    //     }
    //     // console.log('filters', filters)
    //     return filters
    // }

    const getFilters = async () => {
        //debugger
        const timezoneId = localStorage.getItem('timezone_id')
        const clientType = localStorage.getItem('client_type')
        let fromDatefor = fromDate;
        let toDatefor = toDate;
      
        let filters = {};
      
        if (clientType === 'US' && !isGet) {
          try {
            const response = await new Promise((resolve, reject) => {
              //debugger
              getTimezoneList((response) => {
                if (response.status === 200) {
                  resolve(response);
                } else {
                  reject(response);
                }
              });
            });
            //debugger
            const data = response.data;
            console.log(data)
            let timeOffset = data.filter(item => item.Id === parseInt(timezoneId, 10))[0].Time;
            //timeOffset='-16:30';
      
            const now = new Date();
            //console.log(now)
      
            const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
            const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
      
            const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
      
            //debugger
            console.log(now, adjustedTime)
            console.log(adjustedTime.getTime())
            console.log(now.getTime())
      
            if (now.getDate() !== adjustedTime.getDate()) {
              const adjustedDay = adjustedTime.getDate();
              const adjustedMonth = adjustedTime.getMonth();
              const adjustedYear = adjustedTime.getFullYear();
      
              setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
              setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));
      
              fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
              toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
            }
      
            const nowHours = now.getHours();
            const nowMinutes = now.getMinutes();
            const adjustedHours = adjustedTime.getHours();
            const adjustedMinutes = adjustedTime.getMinutes();
      
            if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
              const adjTimeValue = dayjs(adjustedTime)
              setToDate(adjTimeValue);
              toDatefor = adjTimeValue;
            }
          } catch (error) {
            console.error(error);
          }
        }
      
        const formatDateTime = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
      
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
      
        if (IsAgentBased === '0') {
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));
      
          filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS');
          filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS');
        } else {
      
          if (fromDatefor && !toDatefor) {
            filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS')
          } else if (!fromDatefor && toDatefor) {
            filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS')
          } else if (fromDatefor && toDatefor) {
            filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS')
            filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS')
          }
        }
        if (detectedType) {
          filters.detectedtype = (detectedType.map((item) => item.Id)).join(',')
        }
        if (device) {
          filters.camera = (device.map((item) => item.uid ? item.uid : item)).join(',')
        }
        // console.log('filters', filters)
        return filters;
      }

    useEffect(() => {
        getCameras()
        getAnalytics()
    }, [])

    // useEffect(() => {
    //    //debugger
    //     if (alldata.isGetData) {
    //         if (alldata.isLoad === false)
    //             dataSetup({
    //                 ...alldata,
    //                 isLoad: true
    //             })
           
            
    //         const body = !alldata.isReloadForNewData ? jsonToParam(getFilters()) : jsonToParam(
    //             getFilters()
    //         )

    //         const apiURL = `/client-info/get_client_audits?${body}`
    //         axios({
    //             method: 'get',
    //             url: getURL(apiURL),
    //             validateStatus: (status) => handleResponse(status),
    //         }).then(function (response) {
    //             if (response.status === 200 || response.status === 201) {
    //                 // const resData = response.data.hasOwnProperty('client_audits') ? Object.values(response.data.client_audits): []
    //                 const resData = response.data ? response.data : []  
    //                 setResData(resData)       
    //                 // console.log('resData', resData)
                    
                    
    //                 if (alldata.isReloadForNewData) {

    //                     let auditsdata = []
    //                     const lastAuditId = (!alldata.reload || !alldata.lastId) && resData.length ? getId(resData, 'last') : alldata.lastId
    //                     const firstAuditId = alldata.reload && resData.length ? getId(resData, 'first') : alldata.firstId

    //                     // console.log('TEST ALLDATA',alldata.reload, alldata.firstId, getDataFromLastId(alldata.data, alldata.firstId))
    //                     // console.log("alldata.reload && alldata.firstId",alldata.reload && alldata.firstId)
    //                     if (alldata.reload && alldata.firstId) {
    //                         // let managedData = getDataFromLastId(resData, alldata.firstId)

    //                         auditsdata = [...resData, ...alldata.data]
    //                         // console.log("auditsdata",...resData)
    //                         if (resData.length > 0) {
    //                             const validCameras = resData.filter((item) => BUZZER_CAMS.includes(Number(item.CameraId)))
    //                             // console.log(isPlayBuzzer)
    //                             if (validCameras.length > 0 && userType && Number(userType) === 33) {
    //                                 playBuzzer()
    //                             }
    //                             setOpen(true)
    //                             setTimeout(() => setOpen(false), 1000 * 30)
    //                         }
    //                     }
    //                     else
    //                         auditsdata = lastAuditId !== alldata.lastId ? alldata.data.concat(resData) : alldata.data


    //                     dataSetup({
    //                         ...alldata,
    //                         isLoad: false,
    //                         isGetData: false,
    //                         data: auditsdata,
    //                         filterData: filterData(search, auditsdata),
    //                         lastId: lastAuditId,
    //                         reload: false,
    //                         firstId: firstAuditId
    //                     })

    //                     // console.loresData

    //                 } else {
    //                     dataSetup({
    //                         ...alldata,
    //                         isLoad: false,
    //                         isGetData: false,
    //                         customFilterData: resData,
    //                         filterData: filterData(search, resData),
    //                         reload: false
    //                     })
    //                 }

                    

    //             } else
    //                 dataSetup({
    //                     ...alldata,
    //                     isLoad: false,
    //                 })

    //         })
    //     }
    // }, [alldata.isGetData])

    useEffect(() => {
        //debugger
        const fetchData = async () => {
            //debugger
          if (alldata.isGetData) {
            if (alldata.isLoad === false)
              dataSetup({
                ...alldata,
                isLoad: true
              })
      
            const filters = await getFilters();
            //debugger
            const body = jsonToParam(filters);
            const apiURL = `/client-info/get_client_audits?${body}`
            axios({
              method: 'get',
              url: getURL(apiURL),
              validateStatus: (status) => handleResponse(status),
            }).then(function (response) {
              if (response.status === 200 || response.status === 201) {
                const resData = response.data ? response.data : []
                setResData(resData)
      
                if (alldata.isReloadForNewData) {
                  let auditsdata = []
                  const lastAuditId = (!alldata.reload || !alldata.lastId) && resData.length ? getId(resData, 'last') : alldata.lastId
                  const firstAuditId = alldata.reload && resData.length ? getId(resData, 'first') : alldata.firstId
      
                  if (alldata.reload && alldata.firstId) {
                    auditsdata = [...resData, ...alldata.data]
                    if (resData.length > 0) {
                      const validCameras = resData.filter((item) => BUZZER_CAMS.includes(Number(item.CameraId)))
                      if (validCameras.length > 0 && userType && Number(userType) === 33) {
                        playBuzzer()
                      }
                      setOpen(true)
                      setTimeout(() => setOpen(false), 1000 * 30)
                    }
                  }
                  else
                    auditsdata = lastAuditId !== alldata.lastId ? alldata.data.concat(resData) : alldata.data
      
                  dataSetup({
                    ...alldata,
                    isLoad: false,
                    isGetData: false,
                    data: auditsdata,
                    filterData: filterData(search, auditsdata),
                    lastId: lastAuditId,
                    reload: false,
                    firstId: firstAuditId
                  })
      
                } else {
                  dataSetup({
                    ...alldata,
                    isLoad: false,
                    isGetData: false,
                    customFilterData: resData,
                    filterData: filterData(search, resData),
                    reload: false
                  })
                }
              } else
                dataSetup({
                  ...alldata,
                  isLoad: false,
                })
            })
          }
        }
        fetchData();

        if(isGet){
          setIsGet(false)
        }
      }, [alldata.isGetData])

    const getId = (data, order) => {
        let id = data.length ? (order === 'last' ? data[data.length - 1].Id : data[0].Id) : null
        // console.log('data',data)
        // for (let x of data)
        //     id = id === null || (order === 'last' && parseInt(x.Id) < id) || (order === 'first' && parseInt(x.Id) > id) ? parseInt(x.Id) : id
        return id
    }

    const getDataFromLastId = (data, lastId) => [...data.filter((item) => parseInt(item.Id) > parseInt(lastId))]

    const loadMoreAudits = () => dataSetup({ ...alldata, isGetData: true })

    const reLoadAudits = () => dataSetup({ ...alldata, reload: true, isGetData: true })

    useEffect(() => {
        const intervalId = setInterval(() => {
            // console.log('Interval', alldata.reload)
            if (!alldata.reload && alldata.isReloadForNewData)
                reLoadAudits()
        }, 1000 * RUN_FREQ_TIME)
        return () => clearInterval(intervalId)
    })

    const filterData = (search, data) => {
      const keywords = ['high', 'normal'];
        return data.filter((item) => {
          debugger
          const lowerSearch = search.toLowerCase();
          const containsKeyword = keywords.some(keyword => keyword.includes(lowerSearch));

            if (containsKeyword){         
                let connecttext = `${item.Priority === 1 ? "High" : "Normal"}` 
                connecttext = connecttext.toLowerCase()
                return connecttext.match(String(search).toLowerCase())
            }
            else{
                let connecttext = `${item.DetectedType} ${item.devicename}  ${item.ClientCreatedOn} ${item.EventNo} ${item.EventType}  ${item.EventTime}`
                connecttext = connecttext.toLowerCase()
                return connecttext.match(String(search).toLowerCase())             
            }
        })
    }

    const filterAudits = (value) => {
        //debugger
        console.log(alldata)
        if (value) 
            dataSetup({ ...alldata, filterData: filterData(value, resData) })
        else
            dataSetup({ ...alldata, filterData: filterData('', resData) })
    }

    const updateToClient = (auditId) => {
        setEscalat(false)
        axios({
            method: 'post',
            url: getURL(`/escalation/isescalated?isescalated=1&audit_id=${auditId}`),
            validateStatus: (status) => handleResponse(status),
        }).then((response) => {
            setEscalat(true)
            // console.log(response.data)
        })
    }

    return {
        alldata,
        loadMoreAudits,
        dataSetup,
        reLoadAudits,
        search,
        setSearch,
        filterAudits,
        updateToClient,
        isEscalating,
        open,
        setOpen,
        cameras,
        analytics,
        fromDate,
        toDate,
        handleFromDate,
        handleToDate,
        detectedType,
        handleDetectedType,
        device,
        handleDevice,
        handleDelete,
        handlerGetFilterData,
        alertStatus,
        handleNotificationBar
    }
}

export default useAuditList